export const heading_styles = {
  marginTop: 0,
  marginBottom: 32,
  marginLeft: "auto",
  marginRight: "auto",
  width: "100%",
  textAlign: "center" as const,
  fontSize: "48px",
};

export const flex_container_style = {
  display: "flex",
  margin: "5px",
};

export const flex_row_container_style = {
  ...flex_container_style,
  flexDirection: "row" as const,
  flexWrap: "wrap" as const,
};

export const flex_column_container_style = {
  ...flex_container_style,
  flexDirection: "column" as const,
  flexWrap: "wrap" as const,
};
